import { ref } from 'vue';

const useShowPassword = () => {
  const inputPassword = ref('');
  const showEyesIcon = ref(true);

  const changeType = () => {
    const inputType = inputPassword.value.type;

    if (inputType === 'password') {
      inputPassword.value.type = 'text';
      showEyesIcon.value = false;
    } else {
      inputPassword.value.type = 'password';
      showEyesIcon.value = true;
    }
  };

  return {
    changeType,
    inputPassword,
    showEyesIcon,
  };
};

export default useShowPassword;
