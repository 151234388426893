import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import hasAStringALowercase from '@/utils/validations/hasAStringALowercase';
import hasAStringAUppercase from '@/utils/validations/hasAStringAUppercase';
import  isEqual from '@/utils/validations/isEqual';

const useNewPassword = () => {
  const router = useRouter();
  const store = useStore();
  let select = ref(true);
  let isShow = ref(false);
  let typeModal = ref("result");
  let type = ref("success");

  const isLoading_ = ref(false);

  const userForm = ref({
    newPassword: '',
    newPassword2: '',
  });

  const requeriments = ref({
    hasUppercase: false,
    hasLowercase: false,
    hasMinimum10Characters: false,
    isEqual : false
  });

  const messageForm = ref({
    message: '',
    messageType: '',
  });

  const showOrClose = () =>{
    isShow.value = !isShow.value
  }


  const showMessage = (message, messageType) => {
    isLoading_.value = false;
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
  };

  const handleSubmit = async (token) => {
    isLoading_.value = true;
    isShow.value = true;
    console.log(isEqual(userForm.value.newPassword,userForm.value.newPassword2))
    if (!isEqual(userForm.value.newPassword,userForm.value.newPassword2)) {
      showMessage('Las contraseñas no coinciden', 'error');
    } else {
      const { success, message } = await store.dispatch(
        'auth/registerPassword',
        {
          password: userForm.value.newPassword,
          password_confirmation: userForm.value.newPassword2,
          token,
        }
      );
      const messageType = success ? 'success' : 'error';
      showMessage(message, messageType);
      setTimeout(() => { localStorage.setItem("new-user", true); router.push({ name: 'login' }); }, 3600);
    }
  };

  watchEffect(() => {
    const newPassword = userForm.value.newPassword;
    requeriments.value.hasLowercase = hasAStringALowercase(newPassword);
    requeriments.value.hasUppercase = hasAStringAUppercase(newPassword);

    if (newPassword.length >= 10)
      requeriments.value.hasMinimum10Characters = true;
    if (newPassword.length < 10)
      requeriments.value.hasMinimum10Characters = false;
  });

  return {
    isLoading_,
    requeriments,
    userForm,
    handleSubmit,
    messageForm,
    showOrClose,
    select,
    isShow,
    typeModal,
    type,
  };
};

export default useNewPassword;
