<template>
    <div>
        <slot v-if="isShow"></slot>
    </div>
</template>


<script>
export default {
    props:{
        isShow:{
            type: Boolean,
        }
    }
}
</script>

