<template>
  <div>
    <input type="radio" class="custom-radios" />
    <label for="min-caracter">
      <img
        src="/images/decorations/check-inactive.png"
        alt="check"
        class="inactive"
        v-if="!requirementFulfilled"
      />
      <img
        src="/images/decorations/checkPassword.png"
        alt="check"
        v-if="requirementFulfilled"
        class="active"
      />
      <span class="text-custom">
        {{ requirementText }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    requirementFulfilled: {
      type: Boolean,
      default: false,
    },
    requirementText: {
      type: String,
      default: '',
    },
  },
};
</script>
