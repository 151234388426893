<template>
  <aside class="form-images form-img-info">
    <div class="logo">
      <img
        src="/images/logos/logo-faber.png"
        alt="Faber Castell"
        title="Faber Castell"
      />
    </div>
    <div class="featured hide-mobile">
      <img src="/images/decorations/form-picture.png" alt="Estadistica" />
    </div>
    <span
      v-if="linkText !== ''"
      @click="openModal"
      class="btn-link link-modal-desktop link link-modal"
    >
      {{ linkText }}
    </span>
  </aside>
</template>

<script>
export default {
  props: {
    linkText: { type: String, default: '' },
    openModal: { type: Function },
  },
};
</script>
