import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const useValidateToken = () => {
  const router = useRouter();
  const store = useStore();
  const isLoading = ref(false);

  const validateToken = async (token) => {
    isLoading.value = true;

    const { success, message } = await store.dispatch(
        'auth/registerValidate',
        {
            token,
        }
    );
    const messageType = success ? 'success' : 'error';
    if(messageType==='error') router.push({ name: 'login' }); else  isLoading.value = false;

  }

  return {
    isLoading,
    validateToken,
  };
};

export default useValidateToken;
